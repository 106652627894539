.btn.year-btn {
  background-color: white;
  color: black;
  border: 5px solid #1eacf3;
  border-radius: 25px;
  transition: 0.5s ease;
  padding: 5px 10px;
  height: 45px;
  font-size: 18px;
  margin-left: 20px;
}

@media screen and (max-width: 600px){
  
  .buttons{
    display: none;
  }

  .btn.year-btn
  {
    padding: 0px;
    margin: 0%;
    border: 5px solid #1eacf3;
    border-radius: 25px;
    font-size:10px;
    height: 25px;
    width: 55px;;
    margin-left:5px;
    margin-right:5px;
  }
  .btn.year-btn:focus {
    background-color: #1eacf3;
    border: 5px solid #d8e9f1;
    height: 35px;
    width: 55px;;
    font-size: 14;
  }
  .btn.year-btn:hover {
    position: relative;
    margin-bottom: 10px;
    background-color: #1eacf3;
    height: 35px;
    width: 55px;
  }

  .onclick {
    background-color: #1eacf3;
    border: 4px solid #d8e9f1 !important;
    border-radius: 25px;
    height: 60px !important;
    width: auto;
    font-size: 25px !important;
    padding: 0px;
    margin-left: 0px !important;
    margin-right: 5px;
    margin-top: 5px;
    color: white; 
  }
  
  #heading{
    margin-top:0px;
  }
}

.btn.year-btn:focus {
  background-color: #1eacf3;
  border: 5px solid #d8e9f1;
  height: 55px;
}
.btn.year-btn:hover {
  position: relative;
  margin-bottom: 10px;
  background-color: #1eacf3;
  height: 50px;
}

.onclick {
  background-color: #1eacf3;
  border: 5px solid #d8e9f1;
  border-radius: 25px;
  height: 55px;
  font-size: 18px;
  padding: 5px 10px;
  margin-left: 20px;
  text-align: center;
  color: white;
}

#btn-0 {
  margin-left: 20px;
}
#btn-1 {
  margin-left: 20px;
}
#btn-2 {
  margin-left: 20px;
}
#btn-3 {
  margin-left: 20px;
}
#btn-4 {
  margin-left: 20px;
}
#btn-5 {
  margin-left: 20px;
}
#btn-6 {
  margin-left: 20px;
}
* {
  font-family: "Rochester", cursive;
  font-size: 24px;
}

.body {
  /* background-image: url("../assets/backgroundImages/Background1.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-x: hidden !important ;
}
.home {
  max-width: 1200px;
  margin-top: 20vh;
  margin-left: auto;
  margin-right: auto;
}
.heading {
  height: 20vh;

  position: fixed;
  margin-top: 20px;
  top: 0px;
  /* top:40px; */
  width: 100%;
  z-index: 20;
  transition: all 0.3s ease;
  /*background-color: black; */
}
.years-to-select {
  position: absolute;
  left: 0px;
  bottom: 15px;
  z-index: 10;

  width: 100%;
}

.home-bg {
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.into-img {
  max-width: 900px;
}

/* Aweards11_14 css */
/* .jury-flex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.img-brick {
  width: auto;
  margin-bottom: 25px;
}
.img-space {
  display: block;
}
.img-space img {
  height: 140px;
  transform: scale(1.3);
}
.img-info {
  text-align: center;
}
.img-info span {
  margin-top: -16px;
  display: block;
  font-family: Rochester;
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: #1eacf3;
}
.journey-block {
  margin-top: -30px;
  width: 100%;
  padding: 10px 25px 25px 25px;
}
.journey-rich {
  text-shadow: -3px 5px 24px #1eacf3, 0 2px 9px rgb(8 255 147 / 50%);
  font-family: Rochester;
  color: #ffffff;
  font-size: 40px;
  width: 100%;
  padding: 15px;
  text-align: center;
  display: block;
}

.journey-text {
  display: block;
  font-family: Helvetica;
  width: 100%;
  line-height: 26px;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  padding: 0px 10vw 0px 10vw;
}
.bg.awards11 {
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  background-image: url("../assets/backgroundImages/Background1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
} */

.jury-flex{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.img-brick{
  width: auto;
  margin-bottom: 25px;
}
.img-space{
  display: block;
}
.img-space img{
  height: 140px;
  transform: scale(1.3);
}
.img-info{
  text-align: center;
}
.img-info span{
  margin-top: -16px;
  display: block;
  font-family: Rochester;
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: #1eacf3;
}
.journey-block{
  margin-top: -30px;
  width:100%;
  padding: 10px 25px 25px 25px;
}
.journey-rich{
  text-shadow: -3px 5px 24px #1eacf3, 0 2px 9px rgb(8 255 147 / 50%);
  font-family: Rochester;
  color: #ffffff;
  font-size: 40px;
  width: 100%;
  padding: 15px;
  text-align: center;
  display: block;
}


.journey-text {
  display: block;
  font-family: Helvetica;
  width: 100%;
  line-height: 26px;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  padding: 0px 10vw 0px 10vw;
}
.bg.awards11
{
  width:100%;
  height:100vh;
  overflow:auto;
  display:flex;
  align-items:center;
  background-image: url("../assets/backgroundImages/Background1.jpg");
  background-size: cover;
  background-repeat:no-repeat;
}

.home-bg {
background-size: cover;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.navigtors
{
font-size: 34px;
color: #ffc114db;
}
.into-img {
max-width: 900px;
}

@media screen and (min-width:600px) {

  .into-img {
    max-width: 550px !important;
  }

.img-space img{
  height: 145px !important;
  transform: scale(1.3) !important;
  padding-bottom: 10px !important;
}
.img-info span{
  font-size: 30px !important;
}
img, svg {
  vertical-align: middle !important;
}
  .journey-rich {
    text-shadow: -3px 5px 24px #1eacf3, 0 2px 9px rgb(8 255 147 / 50%);
    font-family: Rochester;
    color: #ffffff;
    font-size: 40px;
    width: 100%;
    padding: 15px;
    text-align: center;
    display: block;
  }

.journey-text {
    
    width: 100%;
    line-height: 26px !important;
    text-align: justify !important;
    font-size: 20px !important;
    padding: 2px !important;
    
}

}

@media screen and (max-width:600px){
.into-img{
  max-width: 400px !important;
  margin-bottom: 100px;
}
.navigtors {
  font-size: 28px;
  color: #ffc114db;
  margin-bottom: 71px;
  text-align: center;
  margin-right: 14px;
}
.img-space img{
  height: 100pt !important;
  transform: scale(1.2) !important;
  padding-bottom: 7px !important;
}


.img-info span{
  font-size: medium !important;
}
.journey-block{
  padding-bottom:140px !important;
}
.jury-flex
{
  padding-bottom :5px !important;
}
  .journey-rich {
    font-size: 22px !important;
  width: 100% !important;
  padding: 5px !important;
  text-align: center !important;
  display: block !important;
  }

.journey-text {
    
  width: 100%;
  line-height: 16px !important;
  text-align: justify !important;
  font-size: 12px !important;
  padding: 2px !important;
}

}

.jury-flex {
display: flex;
justify-content: space-evenly;
align-items: center;
width: 100%;
}
.img-brick {
width: auto;
margin-bottom: 25px;
}
.img-space {
display: block;
}
.img-space img {
height: 140px;
transform: scale(1.3);
}
.img-info {
text-align: center;
}
.img-info span {
margin-top: -16px;
display: block;
font-family: Rochester;
width: 100%;
text-align: center;
font-size: 30px;
color: #1eacf3;
}
.journey-block {
margin-top: -30px;
width: 100%;
padding: 10px 25px 25px 25px;
}
.journey-rich {
text-shadow: -3px 5px 24px #1eacf3, 0 2px 9px rgb(8 255 147 / 50%);
font-family: Rochester;
color: #ffffff;
font-size: 40px;
width: 100%;
padding: 15px;
text-align: center;
display: block;
}

.journey-text {
display: block;
font-family: Helvetica;
width: 100%;
line-height: 26px;
text-align: center;
font-size: 18px;
color: #ffffff;
padding: 0px 10vw 0px 10vw;
}
.bg.awards11 {
width: 100%;
height: 100vh;
overflow: auto;
display: flex;
align-items: center;
background-image: url("../assets/backgroundImages/Background1.jpg");
background-size: cover;
background-repeat: no-repeat;
}

/* choice awards */
.c-class-image {
  height: 100px;
  transform: scale(1.8);
  cursor: pointer;
}
.c-class-margin {
  margin-left: 0px;
}
.choice-awards-outer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  /* background-image: url('../assets/backgroundImages/Background1.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.navigators {
  text-shadow: -3px 5px 24px #1eacf3, 0 2px 9px rgba(8, 255, 147, 0.5);
  font-family: Rochester;
  color: #fff;
  font-size: 40px;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  /* margin-top: 10vh; */
}
.award-name {
  text-shadow: -3px 5px 24px #1eacf3, 0 2px 9px rgba(8, 255, 147, 0.5);
  font-family: Rochester;
  color: #fff;
  font-size: 15px;
  text-align: center;
}
/* choice awards */

.star-performer-image {
  margin-left: -25px;
}

/* .star-images>img
{
  height: 100px;
} */

.c-class-image::before,
.c-class-image::after {
  --scale: 0;
  --arrow-size: 20px;
  --tooltip-color: green;

  position: absolute;
  top: -0.25rem;
  left: 50%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}
.c-class-image::before {
  --translate-y: calc(-100% - var(--arrow-size));

  content: attr(data-tooltip);
  color: white;
  padding: 0.5rem;
  border-radius: 0.3rem;
  text-align: center;
  width: max-content;
  max-width: 100%;
  background: var(--tooltip-color);
}
.c-class-image:hover:before,
.c-class-image:hover:after {
  --scale: 1;
}

.c-class-image::after {
  --translate-y: calc(-1 * var(--arrow-size));

  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: white !important;
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: black !important;
  text-align: start !important;
  background-color: white !important;
  border-radius: var(--bs-tooltip-border-radius);
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  align-items: center;
}


.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 195px !important;
  --bs-tooltip-padding-x: 0rem;
  --bs-tooltip-padding-y: 2.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  margin-left : -25px !important;
  
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .bs-tooltip-bottom .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: white !important;
}

#button-tooltip{
  display: flex;
  justify-content: center;
  margin-top: "10px";
  /* margin-top : -35px !important; */
}

.choice-1, .coreValue-1, .individualAward-1, .jury-1, .ManagedServices-1, .projectFixed-1, .projectStaff-1, .projectStar-1, .projectStrategic-1, .salesAward-1, .sharedServices-1, .tmSupport-1,.trueChampion-1, .mostValuabe-1{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size : 17px ;
}

.choice-2, .coreValue-2, .individualAward-2, .jury-2, .ManagedServices-2, .projectFixed-2, .projectStaff-2, .projectStar-2, .projectStrategic-2, .salesAward-2, .sharedServices-2, .tmSupport-2,.trueChampion-2, .mostValuabe-2{
  font-family: HelveticaNeue ;
  font-size: 14px;
  color: #139ded ;
  padding-top: 15px;
}

.choice-3, .coreValue-3, .individualAward-3, .jury-3, .ManagedServices-3, .projectFixed-3, .projectStaff-3, .projectStar-3, .projectStrategic-3, .salesAward-3, .sharedServices-3, .tmSupport-3,.trueChampion-3, .mostValuabe-3{
  color: blue ; 
  width: 100% ; 
  margin-top: 5px ;
  margin-bottom : 5px ;
}

.choice-4{
  padding-top : 150px;
  padding-right : 200px;
  padding-left : 200px;
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

/* .choice-5, .coreValue-5, .individualAward-5, .jury-5, .ManagedServices-5, .projectFixed-5, .projectStar-5, .projectStrategic-5, .salesAward-5, .sharedServices-5, .tmSupport-5{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  overflow: hidden;
  padding: 100px;
} */

.choice-5, .coreValue-5, .individualAward-5, .jury-5, .ManagedServices-5, .projectFixed-5, .projectStaff-5, .projectStar-5, .projectStrategic-5, .salesAward-5, .sharedServices-5, .tmSupport-5, .trueChampion-5, .mostValuabe-5{
  display: flex;
  align-items: center;
  justify-content: space-around ;
  flex-direction: row;
  text-align: center;
  border: 10px;
  padding-bottom : 150px;
}

.choice-6,  .coreValue-6, .individualAward-6, .jury-6, .ManagedServices-6, .projectFixed-6, .projectStaff-6, .projectStar-6, .projectStrategic-6, .salesAward-6, .sharedServices-6, .tmSupport-6, .trueChampion-6, .mostValuabe-6{
  height : 180px;
}

.choice-7, .coreValue-7, .individualAward-7, .jury-7, .ManagedServices-7, .projectFixed-7, .projectStaff-7, .projectStar-7, .projectStrategic-7, .salesAward-7, .sharedServices-7, .tmSupport-7{
  display : flex;
  justify-content : center;
}

.choice-8,  .coreValue-8, .individualAward-8, .jury-8, .ManagedServices-8, .projectFixed-8, .projectStaff-8, .projectStar-8, .projectStrategic-8, .salesAward-8, .sharedServices-8, .tmSupport-8{
 height : 100px;
 text-align : center;
}

.choice-9,  .coreValue-9, .individualAward-9, .jury-9, .ManagedServices-9, .projectFixed-9, .projectStaff-9, .projectStar-9, .projectStrategic-9, .salesAward-9, .sharedServices-9, .tmSupport-9{
  font-family: Rochester;
  color: white;
  font-size: 21px;
  width : 220px;
  padding-top: 15px;
  margin : auto;
}

.coreValue-4{
  padding-top : 150px;
  padding-right : 230px;
  padding-left : 200px;
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.individualAward-4{
  padding-top : 150px;
  padding-right : 150px;
  padding-left : 120px; 
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.jury-4{
  padding-top : 150px;
  padding-right : 210px;
  padding-left : 210px; 
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.ManagedServices-4{
  padding-top : 150px;
  padding-right : 100px;
  padding-left : 100px; 
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.mostValuabe-4{
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.projectFixed-4{
  padding-top : 150px;
  padding-right : 100px;
  padding-left : 100px; 
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.projectStaff-4{
  padding-top : 150px;
  padding-right : 100px;
  padding-left : 100px; 
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.projectStar-4{
  padding-top : 150px;
  padding-right : 230px;
  padding-left : 210px; 
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.projectStrategic-4{
  padding-top : 150px;
  padding-right : 200px;
  padding-left : 130px; 
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.salesAward-4{
  padding-top : 150px;
  padding-right : 100px;
  padding-left : 100px; 
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.sharedServices-4{
  padding-top: 140px;
  padding-right : 200px;
  padding-left : 200px; 
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.tmSupport-4{
  padding-top : 150px;
  padding-right : 150px;
  padding-left : 150px; 
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.trueChampion-4{
  padding-right : 150px;
  padding-left : 120px; 
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: Flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font: white;
}

.trueChampion-8, .mostValuabe-8{
  height: 200px;
  margin-top: 10px;
}
/* 
.placementTrigger{
  placement : "right";
} */


@media only screen and (max-width: 600px){

  .choice-1, .coreValue-1, .individualAward-1, .jury-1, .ManagedServices-1, .projectFixed-1, .projectStaff-1, .projectStar-1, .projectStrategic-1, .salesAward-1, .sharedServices-1, .tmSupport-1,.trueChampion-1, .mostValuabe-1{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-size : 12px ;
  }
  
  .choice-2, .coreValue-2, .individualAward-2, .jury-2, .ManagedServices-2, .projectFixed-2, .projectStaff-2, .projectStar-2, .projectStrategic-2, .salesAward-2, .sharedServices-2, .tmSupport-2,.trueChampion-2, .mostValuabe-2{
    font-family: HelveticaNeue ;
    font-size: 10px;
    color: #139ded ;
    padding-top: 8px;
  }

  .choice-3, .coreValue-3, .individualAward-3, .jury-3, .ManagedServices-3, .projectFixed-3, .projectStaff-3, .projectStar-3, .projectStrategic-3, .salesAward-3, .sharedServices-3, .tmSupport-3,.trueChampion-3, .mostValuabe-3{
    color: blue ; 
    width: 100% ; 
    margin-top: 5px ;
    margin-bottom : 5px ;
  }
  
  .choice-4, .individualAward-4 {
    /* height : 100vh !important; */
    padding-top : 0px;
    padding-right : 0px;
    padding-left : 0px;
  }

  .choice-4, .coreValue-4, .jury-4, .individualAward-4, .ManagedServices-4, .projectFixed-4, .projectStaff-4, .projectStar-4, .projectStrategic-4, .salesAward-4, .sharedServices-4, .tmSupport-4{
    padding-top: 100px;
    padding-left: 0px;
    padding-right: 0px;
  }

  
  .trueChampion-4, .mostValuabe-4{
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .projectStar-8{
    height: 45px;
  }
  
  .choice-5, .coreValue-5, .individualAward-5, .jury-5, .ManagedServices-5, .projectFixed-5, .projectStaff-5, .projectStar-5, .projectStrategic-5, .salesAward-5, .sharedServices-5, .tmSupport-5, .trueChampion-5, .mostValuabe-5 {
    display : flex !important;
    height : 100%;
    margin-bottom : 20px;
    flex-direction : row !important;
  }
  .choice-6,  .coreValue-6, .individualAward-6, .jury-6, .ManagedServices-6, .projectFixed-6, .projectStaff-6, .projectStar-6, .projectStrategic-6, .salesAward-6, .sharedServices-6, .tmSupport-6, .trueChampion-6, .mostValuabe-6 {
    height : 100px;
  }

  .choice-8,  .coreValue-8, .individualAward-8, .jury-8, .projectFixed-8, .projectStaff-8, .projectStrategic-8, .sharedServices-8, .tmSupport-8{
    height : 60px;
  }

  .choice-9,  .coreValue-9, .individualAward-9, .jury-9, .ManagedServices-9, .projectFixed-9, .projectStaff-9, .projectStar-9, .projectStrategic-9, .salesAward-9, .sharedServices-9, .tmSupport-9{
    font-size : 10px;
  }
  
  .choiceName, .coreValueName, .individualAwardName, .juryName, .managedServicesName, .mostValuabeName, .projectFixedName, .projectStaffName .projectStarName, .projectStrategicName, .salesAwardName, .sharedServicesName, .trueChampionName, .tmSupportName{ 
    display: flex;
    width: 400px;
    justify-content: center; 
    font-size: 30px;
  }

  .salesAward-8{
    height: 50px;
  }

  .mostValuabe-8{
    height: 100px;
  }

  .ManagedServices-8{
    height: 45px;
  }

  .mostValuabe-5{
    height: 60px;
  }

  .projectStrategic-4{
    padding-right: 0px;
    padding-left: 0px;
  }

  .tmSupportName{
    font-size: 22px;
  }

  .trueChampion-8{
    height: 100px;
  }

  .trueChampion-5{
    height: 60px;
  }

  .coreValueName{
    width: 100px;
  }

  .choice-5{
    width: 250px !important;
  }

  .coreValue-5{
    width: 350px !important;
  }

  .individualAward-5{
    width: 350px !important;
  }

  .jury-5 {
    width: 350px !important;
  }

  .ManagedServices-5{
    width: 350px !important;
  }

  .mostValuabe-5{
    width: 350px !important;
  }

  .projectFixed-5{
    width: 350px !important;
  }

  .projectStaff-5{
    width: 350px !important;
  }

  .projectStar-5{
    width: 350px !important;
  }

  .projectStrategic-5{
    width: 350px !important;
  }

  .salesAward-5{
    width: 350px !important;
  }

  .sharedServices-5{
    width: 350px !important;
  }

  .tmSupport-5{
    width: 360px !important;
  }

  .trueChampion-5{
    width: 350px !important;
  }

  .tooltip{
    --bs-tooltip-padding-x: 0.0rem !important;
    --bs-tooltip-padding-y: 0.0rem !important;
  }

  
  .tooltip {
    margin-left: 0px !important;
  }
  
}

@media only screen and (min-width:600px) and (max-width: 900px){

  .choice-1, .coreValue-1, .individualAward-1, .jury-1, .ManagedServices-1, .projectFixed-1, .projectStaff-1, .projectStar-1, .projectStrategic-1, .salesAward-1, .sharedServices-1, .tmSupport-1,.trueChampion-1, .mostValuabe-1{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-size : 17px ;
  }
  
  .choice-2, .coreValue-2, .individualAward-2, .jury-2, .ManagedServices-2, .projectFixed-2, .projectStaff-2, .projectStar-2, .projectStrategic-2, .salesAward-2, .sharedServices-2, .tmSupport-2,.trueChampion-2, .mostValuabe-2{
    font-family: HelveticaNeue ;
    font-size: 14px;
    color: #139ded ;
    padding-top: 15px;
  }

  .choice-3, .coreValue-3, .individualAward-3, .jury-3, .ManagedServices-3, .projectFixed-3, .projectStaff-3, .projectStar-3, .projectStrategic-3, .salesAward-3, .sharedServices-3, .tmSupport-3,.trueChampion-3, .mostValuabe-3{
    color: blue ; 
    width: 100% ; 
    margin-top: 5px ;
    margin-bottom : 5px ;
  }
 

  .choice-4, .coreValue-4, .jury-4, .individualAward-4, .ManagedServices-4, .projectFixed-4, .projectStaff-4 ,.projectStar-4, .projectStrategic-4, .salesAward-4, .sharedServices-4, .tmSupport-4{
    padding-top: 200px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .trueChampion-4, .mostValuabe-4{
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }


  .projectStar-8{
    height: 80px;
  }
  
  .choice-5, .coreValue-5, .individualAward-5, .jury-5, .ManagedServices-5, .projectFixed-5, .projectStaff-5, .projectStar-5, .projectStrategic-5, .salesAward-5, .sharedServices-5, .tmSupport-5, .trueChampion-5, .mostValuabe-5 {
    display : flex !important;
    height : 100%;
    margin-bottom : 20px;
    flex-direction : row !important;
  }

  
  .choice-6,  .coreValue-6, .individualAward-6, .jury-6, .ManagedServices-6, .projectFixed-6, .projectStaff-6, .projectStar-6, .projectStrategic-6, .salesAward-6, .sharedServices-6, .tmSupport-6, .trueChampion-6, .mostValuabe-6 {
    height : 150px;
  }

  .choice-8,  .coreValue-8, .individualAward-8, .jury-8, .projectFixed-8, .projectStaff-8, .projectStrategic-8, .salesAward-8, .sharedServices-8, .tmSupport-8{
    height : 80px;
  }

  .choice-9,  .coreValue-9, .individualAward-9, .jury-9, .ManagedServices-9, .projectFixed-9, .projectStaff-9, .projectStar-9, .projectStrategic-9, .salesAward-9, .sharedServices-9, .tmSupport-9{
    font-size : 10px;
  }
  
  .choiceName, .coreValueName, .individualAwardName, .juryName, .managedServicesName, .mostValuabeName, .projectFixedName, .projectStaffName .projectStarName, .projectStrategicName, .salesAwardName, .sharedServicesName, .trueChampionName, .tmSupportName{ 
    display: flex;
    width: 400px;
    justify-content: center; 
    font-size: 30px;
  }

  .mostValuabe-8{
    height: 100px;
  }

  .ManagedServices-8{
    height: 45px;
  }

  .mostValuabe-5{
    height: 60px;
  }

  .projectStrategic-4{
    padding-right: 0px;
    padding-left: 0px;
  }

  .tmSupportName{
    font-size: 20px;
  }

  .trueChampion-8{
    height: 100px;
  }

  .trueChampion-5{
    height: 60px;
  }

  .coreValueName{
    width: 100px;
  }

  .choice-5, .coreValue-5, .individualAward-5, .jury-5 .ManagedServices-5 .mostValuabe-5 .projectFixed-5, .projectStaff-5{
    width: 350px !important;
  }

  .projectStar-5, .projectStrategic-5, .salesAward-5, .sharedServices-5, .tmSupport-5{
    width: 350px !important;
  }

  .trueChampion-5{
    width: 250px !important;
  }

  .tooltip{
    --bs-tooltip-padding-x: 0.0rem !important;
    --bs-tooltip-padding-y: 0.0rem !important;
    margin-left: 0px !important;
  }
}

@media only screen and (min-width:900px) and (max-width: 1200px){

  .choice-1, .coreValue-1, .individualAward-1, .jury-1, .ManagedServices-1, .projectFixed-1, .projectStaff-1, .projectStar-1, .projectStrategic-1, .salesAward-1, .sharedServices-1, .tmSupport-1,.trueChampion-1, .mostValuabe-1{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-size : 17px ;
  }
  
  .choice-2, .coreValue-2, .individualAward-2, .jury-2, .ManagedServices-2, .projectFixed-2, .projectStaff-2, .projectStar-2, .projectStrategic-2, .salesAward-2, .sharedServices-2, .tmSupport-2,.trueChampion-2, .mostValuabe-2{
    font-family: HelveticaNeue ;
    font-size: 14px;
    color: #139ded ;
    padding-top: 15px;
  }

  .choice-3, .coreValue-3, .individualAward-3, .jury-3, .ManagedServices-3, .projectFixed-3, .projectStaff-3, .projectStar-3, .projectStrategic-3, .salesAward-3, .sharedServices-3, .tmSupport-3,.trueChampion-3, .mostValuabe-3{
    color: blue ; 
    width: 100% ; 
    margin-top: 5px ;
    margin-bottom : 5px ;
  }
 

  .choice-4, .coreValue-4, .jury-4, .individualAward-4, .ManagedServices-4, .projectFixed-4, .projectStaff-4, .projectStar-4, .projectStrategic-4, .salesAward-4, .sharedServices-4, .tmSupport-4{
    padding-top: 200px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .trueChampion-4, .mostValuabe-4{
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }


  .projectStar-8{
    height: 80px;
  }
  
  .choice-5, .coreValue-5, .individualAward-5, .jury-5, .ManagedServices-5, .projectFixed-5, .projectStaff-5,.projectStar-5, .projectStrategic-5, .salesAward-5, .sharedServices-5, .tmSupport-5, .trueChampion-5, .mostValuabe-5 {
    display : flex !important;
    height : 100%;
    margin-bottom : 20px;
    flex-direction : row !important;
  }

  
  .choice-6,  .coreValue-6, .individualAward-6, .jury-6, .ManagedServices-6, .projectFixed-6, .projectStaff-6,.projectStar-6, .projectStrategic-6, .salesAward-6, .sharedServices-6, .tmSupport-6, .trueChampion-6, .mostValuabe-6 {
    height : 210px;
  }

  .choice-8,  .coreValue-8, .individualAward-8, .jury-8, .projectFixed-8, .projectStaff-8, .projectStrategic-8, .salesAward-8, .sharedServices-8, .tmSupport-8{
    height : 100px;
  }

  .choice-9,  .coreValue-9, .individualAward-9, .jury-9, .ManagedServices-9, .projectFixed-9, .projectStaff-9, .projectStar-9, .projectStrategic-9, .salesAward-9, .sharedServices-9, .tmSupport-9{
    font-size : 30px;
    width: 400px;
  }
  
  .choiceName, .coreValueName, .individualAwardName, .juryName, .managedServicesName, .mostValuabeName, .projectFixedName, .projectStaffName, .projectStarName, .projectStrategicName, .salesAwardName, .sharedServicesName, .trueChampionName, .tmSupportName{ 
    display: flex;
    width: 400px;
    justify-content: center; 
    font-size: 40px;
  }
   
  /* .individualAward-9{
    font-size: 25px;
    width: 400px ;
  } */

  .mostValuabe-8{
    height: 200px;
  }

  .ManagedServices-8{
    height: 100px;
  }

  .mostValuabe-5{
    height: 60px;
  }

  .projectStrategic-4{
    padding-right: 0px;
    padding-left: 0px;
  }

  .tmSupportName{
    font-size: 40px;
  }

  .trueChampion-8{
    height: 200px;
  }

  .trueChampion-5{
    height: 60px;
  }

  .coreValueName{
    width: 100px;
  }

  .choice-5{
    width: 300px !important;
  }

  .coreValue-5{
    width: 300px !important;
  }

  .individualAward-5{
    width: 300px !important;
  }

  .jury-5 {
    width: 300px !important;
  }

  .ManagedServices-5{
    width: 300px !important;
  }

  .mostValuabe-5{
    width: 325px !important;
  }

  .projectFixed-5{
    width: 600px !important;
  }

  .projectStaff-5{
    width: 600px !important;
  }

  .projectStar-5{
    width: 450px !important;
  }

  .projectStrategic-5{
    width: 370px !important;
  }

  .salesAward-5{
    width: 300px !important;
  }

  .sharedServices-5{
    width: 360px !important;
  }

  .tmSupport-5{
    width: 630px !important;
  }

  .trueChampion-5{
    width: 400px !important;
  }

  .tooltip{
    --bs-tooltip-padding-x: 0.0rem !important;
    --bs-tooltip-padding-y: 0.0rem !important;
  }
}

.bg-dark {
  /* position: absolute !important; */
  width: 100%;
  background-color: transparent !important;
}

.navbar-nav{
  color: white !important;
  width: 100vw;
  /* background-color: transparent !important; */
}

.onclick.navbar-nav{
  text-align: center !important;
  padding-right: 12px !important;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 25px !important;
}

#onclick.navbar-nav{
  text-align: center;
}

.navbar{
  padding: 0px !important;
}

@media only screen and (max-width:600px) {
  .heading{
    display: none;
  }

  /* .onclick.navbar-nav{
    background-color: white ;
    border: 0px !important;
    border-radius: 0px !important;
    height: 0px !important;
    font-size: 0px !important;
    padding: 0px !important;
    margin-left: 0px !important;
  } */

}

@media only screen and (min-width:600px) {
  .navbar-toggler{
    display: none;
  }

  .navbar-1{
    display: none !important;
  }

  .onclick.navbar-nav{
    background-color: white ;
    border: 0px !important;
    border-radius: 0px !important;
    height: 0px !important;
    font-size: 0px !important;
    padding: 0px !important;
    margin-left: 0px !important;
  }
  
}
  
.css-xi606m {
    text-align: center;
    display: contents;
}
