Aweards11_14 css
.jury-flex{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }
  .img-brick{
    width: auto;
    margin-bottom: 25px;
  }
  .img-space{
    display: block;
  }
  .img-space img{
    height: 140px;
    transform: scale(1.3);
  }
  .img-info{
    text-align: center;
  }
  .img-info span{
    margin-top: -16px;
    display: block;
    font-family: Rochester;
    width: 100%;
    text-align: center;
    font-size: 30px;
    color: #1eacf3;
  }
  .journey-block{
    margin-top: -30px;
    width:100%;
    padding: 10px 25px 25px 25px;
  }
  .journey-rich{
    text-shadow: -3px 5px 24px #1eacf3, 0 2px 9px rgb(8 255 147 / 50%);
    font-family: Rochester;
    color: #ffffff;
    font-size: 40px;
    width: 100%;
    padding: 15px;
    text-align: center;
    display: block;
  }
  
  
  .journey-text {
    display: block;
    font-family: Helvetica;
    width: 100%;
    line-height: 26px;
    text-align: center;
    font-size: 15px;
    color: #ffffff;
    padding: 0px 10vw 0px 10vw;
  }
.bg.awards11
{
    width:100%;
    height:100vh;
    overflow:auto;
    display:flex;
    align-items:center;
    background-image: url("../assets/backgroundImages/Background1.jpg");
    background-size: cover;
    background-repeat:no-repeat;
}

.home-bg {
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navigtors
{
  font-size: 34px;
  color: #ffc114db;
}
.into-img {
  max-width: 900px;
}

@media screen and (min-width:600px) {
  .img-space img{
    height: 145px !important;
    transform: scale(1.3) !important;
    padding-bottom: 10px !important;
  }
  .img-info span{
    font-size: 30px !important;
  }
  img, svg {
    vertical-align: middle !important;
}
    .journey-rich {
      text-shadow: -3px 5px 24px #1eacf3, 0 2px 9px rgb(8 255 147 / 50%);
      font-family: Rochester;
      color: #ffffff;
      font-size: 40px;
      width: 100%;
      padding: 15px;
      text-align: center;
      display: block;
    }
  
  .journey-text {
      
      width: 100%;
      line-height: 26px !important;
      text-align: justify !important;
      font-size: 20px !important;
      padding: 2px !important;
      
  }

}
@media screen  and (width:540px){
  .into-img{
    max-width: 420px;
    margin-bottom: 100px;
  }
  .navigtors {
    font-size: 30px;
    color: #ffc114db;
    margin-bottom: 71px;
    text-align: center;
    margin-right: 14px;
}
  .img-space img {
    height: 140px !important;
    transform: scale(0.9) !important;
}
.journey-text
{
  font-size: 14px !important;
    text-align: justify !important;
    line-height: 18px !important;
}
.img-info span{
  font-size: medium!important;
}
}

@media screen and (max-width:414px){
  .into-img{
    max-width: 300px;
    margin-bottom: 100px;
  }
  .navigtors {
    font-size: 28px;
    color: #ffc114db;
    margin-bottom: 71px;
    text-align: center;
    margin-right: 14px;
}
  .img-space img{
    height: 100pt !important;
    transform: scale(0.8)!important;
    padding-bottom: 0px !important;
    margin-top: 20px;
    padding-block: 10px
  }
 

  .img-info span{
    font-size: medium !important;
  }
  .journey-block{
    padding-bottom:140px !important;
  }
  .jury-flex
  {
    padding-bottom :5px !important;
  }
    .journey-rich {
      font-size: 22px !important;
    width: 100% !important;
    padding: 5px !important;
    text-align: center !important;
    display: block !important;
    }
  
  .journey-text {
      
    width: 100%;
    line-height: 16px !important;
    text-align: justify !important;
    font-size: 12px !important;
    padding: 2px !important;
  }

}

.jury-flex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.img-brick {
  width: auto;
  margin-bottom: 25px;
}
.img-space {
  display: block;
}
.img-space img {
  height: 140px;
  transform: scale(1.3);
}
.img-info {
  text-align: center;
}
.img-info span {
  margin-top: -16px;
  display: block;
  font-family: Rochester;
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: #1eacf3;
}
.journey-block {
  margin-top: -30px;
  width: 100%;
  padding: 10px 25px 25px 25px;
}
.journey-rich {
  text-shadow: -3px 5px 24px #1eacf3, 0 2px 9px rgb(8 255 147 / 50%);
  font-family: Rochester;
  color: #ffffff;
  font-size: 40px;
  width: 100%;
  padding: 15px;
  text-align: center;
  display: block;
}

.journey-text {
  display: block;
  font-family: Helvetica;
  width: 100%;
  line-height: 26px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  padding: 0px 10vw 0px 10vw;
}
.bg.awards11 {
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  background-image: url("../assets/backgroundImages/Background1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

