
.wrapper
{
     /* width:100%; */
    height:100vh; 
    margin: 0;
     overflow-y: scroll; 
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
}
section{
    /* display: flex;
    align-items: center;
    justify-content: center; */
    scroll-snap-align: start;
    scroll-behavior: smooth;
   
}
.component1
{
    background-image: url(../assets/backgroundImages/Background1.jpg);
}
